<template>
  <p-container class="no-bg">  
    <template slot="left" v-if="showLeft">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>
    <template slot="toolbar">
      <!-- <a-button type="primary" @click="handleEdit">编辑</a-button>
      <a-button type="primary">Excel导出</a-button> -->
      <a-button type="primary" @click="handledelet">删除</a-button>
    </template>
    
    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
    >
    <a
        href="javascript:;"
        @click="updateShowModal(col)"
        slot="updateAction"
        slot-scope="text, col"
        >编辑</a
      >
     
      
    </a-table>    
    <!--关键词-->
    <a-modal v-model="updateVisible" title="专家信息" @ok="handleOk">
        <div>
        <a-input-group>
          <a-row :gutter="2">
              <a-col :span="24">
                <a-form-item label="专家姓名">
                   <a-input v-model="zjxx.zjname" :disabled="false"/>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="所在单位">
                   <a-input v-model="zjxx.dw" :disabled="false"/>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="从事领域">
                   <a-input v-model="zjxx.csly" :disabled="false"/>
                </a-form-item>
              </a-col>
          </a-row>
          
       </a-input-group>
        </div>
      <div>
      </div>
    </a-modal>   
   
  </p-container> 
</template>
<script>
import {getallxx,deletezj,updatezjmessage} from '../../../../../api/result'

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
   
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
     localStorage.removeItem("gjzArry")
      localStorage.setItem('gjzArry',JSON.stringify(selectedRows))
      
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
     localStorage.removeItem("gjzArry")
     localStorage.setItem('gjzArry',JSON.stringify(selectedRows))
     
  },
};
const columns = [
  {
    title: "",
    dataIndex: "edit",
    width: "10%",
    scopedSlots: { customRender: "updateAction" },
  },
  {
    title: "专家姓名",
    dataIndex: "zjname",
    width: "10%",
    
  },
  {
    title: "所在单位",
    dataIndex: "dw",
  },
  {
    title: "从事领域",
    dataIndex: "csly",
  },
  {
    title: "数据日期",
    dataIndex: "sjrq",
  },
];
const dataSource = [
        {
          edit: "编辑",
          key: "0",
          name: "张三",
          company: "单位1",
          job: "从事1",
          date: "2021年1月1日",
        },
]
export default {
    name:'QuickSearch',
    data() {
      return {
      loading: false,
      treeData: [],
      rowSelection,
      dataSource,
      columns,
      updateVisible:false,
      zjxx:''
      }
    },
      computed: {
      type(){
          return this.$route.name.substr(this.$route.name.lastIndexOf("_")+1);
      },
      // showLeft(){
      //   return this.type!='all'
      // }
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },
  mounted(){
getallxx()
.then(res=>{
  this.dataSource=res.data
})
  },
    methods: {
      initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
      updateShowModal(col) {
        this.updateVisible = true;
        this.mdl = { ...col }
        this.zjxx=col
        console.log(col)
      },
      handleOk(e) {
        console.log(e);
        this.updateVisible = false;
        updatezjmessage({
          zjguid:this.zjxx.zjguid,
          zjname:this.zjxx.zjname,
          dw:this.zjxx.dw,
          csly:this.zjxx.csly
        })
        .then(res=>{
          console.log(res)
          this.dataSource=res.data
        })
      },
      handledelet(){
deletezj(
   JSON.parse(localStorage.getItem("gjzArry"))
)
.then(res=>{
  console.log(res)
  if(res.data!=''){
    this.dataSource=res.data
  }
})
      }
    }
 
}
</script>

<style scoped>
</style>>